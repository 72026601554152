<template>
        <div class="min-h-screen flex items-center justify-center py-10">
  <div class="w-full max-w-7xl bg-white shadow-lg rounded-lg overflow-hidden mx-4">
    <div class="p-6 bg-sky-500 text-white text-center">
      <h2 class="text-2xl font-semibold">Profile Preview</h2>
    </div>
    <div class="p-6">
      <div class="mb-6 text-gray-700 text-center">
        <p class="text-lg font-semibold">This is how your profile looks to companies.</p>
        <p class="text-md">Please use the <span class="font-semibold">Edit Profile</span> button to update your information or upload/update your resume.</p>
      </div>



      <div class="p-6">
        <!-- Container for Profile Picture and Details -->
        <div class="flex items-start">
            <!-- Details Section -->
            <div class="flex-grow pr-6">
                <!-- Name and Profile Details -->
                <h3 class="text-2xl font-bold text-gray-800 mb-2">{{ this.profile.first_name }} {{ this.profile.last_name }}</h3>
                <p class="text-gray-600">{{ this.profile.current_role }}</p>
                <p class="text-gray-600">{{ this.profile.location }}</p>
                <div class="flex items-center mb-2">
                    <!-- Email Icon -->
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-500 mr-2" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M3 8l7.879 5.879c.171.171.443.171.614 0L21 8V5c0-1.105-.895-2-2-2H5C3.895 3 3 3.895 3 5v3zm0 1.414l7.879 5.879c.171.171.443.171.614 0L21 9.414V19c0 1.105-.895 2-2 2H5c-1.105 0-2-.895-2-2V9.414z" />
                    </svg>
                    <p class="text-gray-600">{{ this.profile.email }}</p>
                </div>
                <div class="flex items-center">
                    <!-- Phone Icon -->
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-500 mr-2" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M20.01 15.41l-3.75-.44a1.998 1.998 0 00-1.97 1.23l-1.57 3.29c-3.16-1.42-5.69-3.95-7.11-7.11l3.29-1.57c.74-.35 1.18-1.19 1.23-1.97l-.44-3.75A2 2 0 008.01 4H5c-1.1 0-2 .9-2 2 0 8.28 6.72 15 15 15 1.1 0 2-.9 2-2v-3.01c0-1.01-.85-1.86-1.89-1.98z" />
                    </svg>
                    <p class="text-gray-600">{{ this.profile.phone }}</p>
                </div>
            </div>
            <!-- Profile Picture (Placeholder) -->
            <div class="flex-shrink-0 w-32 h-32 bg-gray-200 rounded-full overflow-hidden">
                <!-- Replace with actual image tag -->
                <img src="https://via.placeholder.com/128" alt="Profile Picture" class="w-full h-full object-cover">
            </div>
        </div>
    </div>

    <hr />

    <div class="grid grid-cols-1 sm:grid-cols-3 gap-6 p-6">
        <div class="mb-6">
            <h4 class="text-lg font-semibold text-gray-800">Current Company</h4>
            <p class="text-gray-600">{{ this.profile.current_company }}</p>
        </div>
        <div class="mb-6">
            <h4 class="text-lg font-semibold text-gray-800">Current Experience</h4>
            <p class="text-gray-600">{{ $filters.screenedDate(this.profile.updated_experience_company) }}</p>
        </div>
        <div class="mb-6">
            <h4 class="text-lg font-semibold text-gray-800">Total Experience</h4>
            <p class="text-gray-600">{{ $filters.screenedDate(this.profile.updated_total_experience) }}</p>
        </div>
        <div class="mb-6">
            <h4 class="text-lg font-semibold text-gray-800">Resume</h4>
            <p class="text-gray-600"><a href="#" class="text-blue-500 hover:underline">Download your current resume</a></p>
        </div>
        <div class="mb-6">
            <h4 class="text-lg font-semibold text-gray-800">Past Companies</h4>
            <p class="text-gray-600">{{ $filters.screenedCompanies(this.profile.companies) }}</p>
        </div>
        <div class="mb-6">
            <h4 class="text-lg font-semibold text-gray-800">Visa Status</h4>
            <p class="text-gray-600">{{ this.profile.visa }}</p>
        </div>
        <div class="mb-6">
            <h4 class="text-lg font-semibold text-gray-800">Rate</h4>
            <p class="text-gray-600">{{ $filters.screenedRate(this.profile.rate) }}</p>
        </div>
        <div class="mb-6">
            <h4 class="text-lg font-semibold text-gray-800">Availability</h4>
            <p class="text-gray-600">{{ $filters.screenedAvailability(this.profile.availability) }}</p>
        </div>
        <div class="mb-6">
            <h4 class="text-lg font-semibold text-gray-800">Industry</h4>
            <p class="text-gray-600">{{ this.profile.industry }}</p>
        </div>
        <div class="mb-6">
            <h4 class="text-lg font-semibold text-gray-800">Role</h4>
            <p class="text-gray-600">{{ this.profile.role }}</p>
        </div>
        <div class="mb-6">
            <h4 class="text-lg font-semibold text-gray-800">Contract Type</h4>
            <p class="text-gray-600">{{ this.profile.contract_type }}</p>
        </div>
        <div class="mb-6">
            <h4 class="text-lg font-semibold text-gray-800">Work interstate</h4>
            <p class="text-gray-600">{{ $filters.screenedYesNo(this.profile.work_state) }}</p>
        </div>
        <div class="mb-6">
            <h4 class="text-lg font-semibold text-gray-800">Work overseas</h4>
            <p class="text-gray-600">{{ $filters.screenedYesNo(this.profile.work_overseas) }}</p>
        </div>
  
        <div class="mb-6 sm:col-span-3">
            <h4 class="text-lg font-semibold text-gray-800">Ideal Role Description</h4>
            <p class="text-gray-600">{{ this.profile.ideal_role }}</p>
        </div>
    </div>
    <!-- <router-link to="/marketplace" class="flex items-center ml-3 hover:text-gray-200 hidden"></router-link> -->
    <!-- <button onclick="editProfile()" class="w-full py-3 mt-4 bg-sky-500 text-white font-semibold rounded-lg hover:bg-green-600"> -->
    <router-link to="/marketplace/contractor/profile/edit">
        <button class="w-full py-3 mt-4 bg-sky-500 text-white font-semibold rounded-lg hover:bg-green-600">
            Edit Profile
        </button>
    </router-link>
    </div>
  </div>
</div>
</template>

<script>
let apiEndpoint = process.env.VUE_APP_API_BASEURL
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import BigCardContractor from '../../../components/marketplace/contractor/BigCardContractor.vue';
  
export default {
  name: 'MarketplaceContractorProfileEdit',
  components: {
    Datepicker,
    BigCardContractor
  },
  data() {
    return {
      profile: [],
    };
  },
  methods: {
    async getContractorProfile() {
      try {
        let response = await fetch(apiEndpoint + "/marketplace.php?getContractorAccount", {
          headers: {
            'Authorization': 'Bearer ' +  localStorage.getItem('contractorToken'),
          },
        })
        this.profile = await response.json();
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.getContractorProfile()
  }
}
</script>
  