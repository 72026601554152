<template class="h-screen">
    <div class="pt-7 flex justify-center">
      <div class="bg-white shadow-md rounded-xl px-8 pt-6 pb-8 mb-4 flex flex-col w-1/2">
        <div class="-mt-6 -ml-8 -mr-8 rounded-t-xl bg-sky-500 pt-4 pl-8">
          <h1 class="mb-4 text-2xl leading-none tracking-tight md:text-2xl lg:text-3xl text-white">Registration</h1>
        </div>

        <div class="max-w-2xl mx-auto p-8">
            <!-- Step Navigation with ">" symbol between steps -->
            <div class="flex justify-between items-center mb-8">
                <span
                    v-for="(step, index) in steps"
                    :key="index"
                    class="flex items-center"
                >
                <span
                    :class="[
                        'font-medium text-sm px-4 py-2 rounded-md',
                        currentStep === index ? 'bg-sky-500 text-white' : 'text-gray-700 border',
                        isStepCompleted(index) ? 'bg-green-500 text-white' : '',
                        !isStepAccessible(index) ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
                    ]"
                    @click="goToStep(index)"
                    :disabled="!isStepAccessible(index)"
                >
                {{ step.name }}
              </span>

          <span v-if="index < steps.length - 1" class="mx-2 flex items-center justify-center text-gray-400 text-xs">- - ></span>
        </span>
      </div>
  
      <!-- Step Components -->
      <!-- <StepOne v-if="currentStep === 0" v-model="formData" ref="step0" />
      <StepTwo v-if="currentStep === 1" v-model="formData" ref="step1" />
      <StepThree v-if="currentStep === 2" v-model="formData" ref="step2" />
      <StepFour v-if="currentStep === 3" v-model="formData" ref="step3" /> -->
      <StepOne v-if="currentStep === 0" ref="step0" :formData="formData.stepOne" @submit-step1="handleStep1Data" />
      <StepTwo v-if="currentStep === 1" ref="step1" :formData="formData.stepTwo" @submit-step2="handleStep2Data" />
      <StepThree v-if="currentStep === 2" ref="step2" :formData="formData.stepThree" @submit-step3="handleStep3Data" />
      <StepFour v-if="currentStep === 3" ref="step3" @submit-step4="handleStep4Data" />
  
      <!-- Navigation Buttons -->
      <div class="flex justify-between mt-8">
        <!-- Previous Button (only shown after the first step) -->
        <button
          v-if="currentStep > 0"
          @click="previousStep"
          class="bg-gray-500 text-white px-4 py-2 rounded-md"
        >
          Previous
        </button>
  
        <!-- Next or Submit Button -->
        <div class="ml-auto">
          <button
              v-if="currentStep > 0"
              @click="previousStep"
              class="bg-gray-500 hover:bg-gray-400 text-white px-4 py-2 rounded-md"
          >
            Previous
          </button>

          <!-- Next or Submit Button -->
          <div class="ml-auto">
            <button
                v-if="currentStep < steps.length - 1"
                @click="validateStepAndProceed"
                class="bg-sky-500 hover:bg-sky-400 text-white px-4 py-2 rounded-md"
            >
              Next
            </button>
            <button
                v-if="currentStep === steps.length - 1"
                @click="submitForm"
                class="bg-green-500 hover:bg-green-400 text-white px-4 py-2 rounded-md"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import StepOne from '@/components/marketplace/contractor/register/Step1.vue';
import StepTwo from '@/components/marketplace/contractor/register/Step2.vue';
import StepThree from '@/components/marketplace/contractor/register/Step3.vue';
import StepFour from '@/components/marketplace/contractor/register/Step4.vue';

export default {
  components: { StepOne, StepTwo, StepThree, StepFour },
  data() {
    return {
      currentStep: 0,
      completedSteps: [], // Track completed steps
      steps: [
        { name: 'Login Details' },
        { name: 'Personal Details' },
        { name: 'Career Info' },
        { name: 'Resume Upload' }
      ],
      formData: {
        stepOne: {},
        stepTwo: {},
        stepThree: {},
        stepFour: {}
      }
    };
  },
  methods: {
    // Check if the step is completed
    isStepCompleted(index) {
      return this.completedSteps.includes(index);
    },

    // Check if the step is accessible (either completed or the current step)
    isStepAccessible(index) {
      return index <= this.currentStep || this.isStepCompleted(index);
    },

    // Navigate to a step (if accessible)
    goToStep(index) {
      if (this.isStepAccessible(index)) {
        this.currentStep = index;
      }
    },


    // Handle emitted data from Step 1
    handleStep1Data(stepOneData) {
      this.formData.stepOne = stepOneData;  // Store the data for Step 1
    },

    // Handle emitted data from Step 2
    handleStep2Data(stepTwoData) {
      this.formData.stepTwo = stepTwoData;  // Store the data for Step 2
    },

    // Handle emitted data from Step 3
    handleStep3Data(stepThreeData) {
      this.formData.stepThree = stepThreeData;
    },

    // Handle emitted data from Step 4
    handleStep4Data(stepFourData) {
      this.formData.stepFour = stepFourData;
    },

    // Validate the current step and proceed if valid
    validateStepAndProceed() {
      const currentStepComponent = this.$refs[`step${this.currentStep}`];
      if (currentStepComponent && currentStepComponent.validate()) {
        this.nextStep();
      }
    },

    // Navigate to the next step and mark the current one as completed
    nextStep() {
      if (!this.completedSteps.includes(this.currentStep)) {
        this.completedSteps.push(this.currentStep);
      }
      if (this.currentStep < this.steps.length - 1) {
        this.currentStep++;
      }
    },

    // Navigate to the previous step
    previousStep() {
      if (this.currentStep > 0) {
        this.currentStep--;
      }
    },

    // Submit the form (when all steps are completed)
    submitForm() {
      const currentStepComponent = this.$refs[`step${this.currentStep}`];
      if (currentStepComponent && currentStepComponent.validate()) {
        // Handle form submission logic
        console.log(this.formData);
      }
    }
  }
};
</script>

<style scoped>
button {
  transition: all 0.3s ease;
}
</style>
