<template class="h-screen bg-gray-100">
    <div class="pt-7 flex justify-center">
      <div class="bg-white shadow-md rounded-xl px-8 pt-6 pb-8 mb-4 flex flex-col w-1/2">
        <div v-if="this.error" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
          <span class="block sm:inline">You have entered an invalid username or password</span>
        </div>
        
        <div class="mb-4 flex flex-col" v-if="this.emailSent">
            <span class="text-xl">Password Recovery Email Sent</span>
            <span class="mt-2">If an account with that email exists in our system, we have sent a password recovery link. Please check your inbox and spam folder. If you do not receive an email, please try again later or contact our support team for assistance.</span>
        </div>

        <div class="mb-4" v-if="!this.emailSent">
            <div class="flex flex-col">
                <span class="font-bold">Forgot Your Password?</span>
            <span>Enter your email address below, and we’ll send you a link to reset your password.</span>
            </div>

            <label class="block text-gray-darker text-sm font-bold mt-4" for="username">
                Email address
            </label>
          <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-darker" id="email" v-model="this.recoveryEmail" type="text">
        </div>

        <div class="flex items-end justify-end" v-if="!this.emailSent">
            <button class="bg-olive hover:bg-blue-dark text-white font-bold py-2 px-4 rounded" type="button" @click="submitRecoveryRequest">
                Send Recovery Email
            </button>
        </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
let apiEndpoint = process.env.VUE_APP_API_BASEURL

export default {
  name: 'ContractorForgotPassword',
  components: {
  },
  data() {
    return {
      emailSent: false,
      recoveryEmail: "",
      error: false,
      SignInButtonText: "Sign In"
    };
  },
  methods: {
    async submitRecoveryRequest() {
        // alert(this.recoveryEmail)
        // console.log(axios.get(`${apiEndpoint}/contractor/forgot-password`))
        try {
          const response = await axios.get(`${apiEndpoint}/contractor/forgot-password`, {
            email: this.recoveryEmail
          });
          console.log('Forgot password email sent:', response.data);
        } catch (error) {
          console.error('Error sending forgot password request:', error.response.data);
        }
    },
    submitLogin() {
      this.error = false
      if (this.email != "" && this.password != "") {
        // Build submission form
        let formData = new FormData();
        formData.append('contractorEmail', this.email);
        formData.append('contractorPass', this.password);

        fetch(apiEndpoint + "/auth.php", {
          body: formData,
          method: "POST"
        })
        .then(response => response.json())
        .then(data => {
          console.log(data)
          if (data.success) {
            // Set state
            this.$store.commit('setLoggedIn', true)

            // Write token to localStorage
            localStorage.setItem('contractorToken', data.token);

            // Redirect to profiles
            this.$router.push('profile') 
          } else {
            // Clear values
            this.email = ""
            this.password = ""

            // Set state
            this.$store.commit('setLoggedIn', false)
            // Remove token from local storage
            localStorage.removeItem('contractorToken');

            // Set error to true
            this.error = true
          }
        })
      } else {
        this.error = true
      }
    },

  },
}
</script>
