import axios from 'axios';

let apiEndpoint = process.env.VUE_APP_API_BASEURL

export default {
    getIndustries() {
        return axios.get(`${apiEndpoint}/contractor/industries`);
    },
    getRoles(industryId) {
        return axios.get(`${apiEndpoint}/contractor/roles/${industryId}`);
    },
    getQuestions(roleId) {
        return axios.get(`${apiEndpoint}/contractor/questions/${roleId}`);
    }
}