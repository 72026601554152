<template>
    <div>
      <!-- Error message (shown when there's an error) -->
      <transition name="fade">
        <div v-if="error" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
          <span class="block sm:inline">{{ errorMessage }}</span>
        </div>
      </transition>
  
      <!-- Form Fields -->
      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full px-3">
          <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-email">
            Email
          </label>
          <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-email" type="email" v-model="this.formData.field_email">
        </div>
      </div>
  
      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full md:w-1/2 px-3">
          <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-password">
            Password
          </label>
          <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-password" type="password" v-model="this.formData.field_password">
        </div>
        <div class="w-full md:w-1/2 px-3">
          <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-passwordConfirm">
            Confirm Password
          </label>
          <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-passwordConfirm" type="password" v-model="this.formData.field_passwordConfirm">
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: ['formData'],
    data() {
      return {
        // formData: {
        //     field_email: "",
        //     field_password: "",
        //     field_passwordConfirm: "",
        // },
        error: false,
        errorMessage: "",
        fadeTimeout: null, // To store the timeout for error message fade out
      };
    },
    methods: {
      showError(message) {
        this.error = true;
        this.errorMessage = message;
  
        // Clear previous timeout if it exists
        if (this.fadeTimeout) {
          clearTimeout(this.fadeTimeout);
        }
  
        // Hide the error after 5 seconds
        this.fadeTimeout = setTimeout(() => {
          this.error = false;
        }, 5000); // Error message stays visible for 5 seconds
      },
  
      validate() {
        // Reset error state
        this.error = false;
        this.errorMessage = '';
  
        let isValid = true;
  
        // Validate password and confirmation match
        if (this.formData.field_password !== this.formData.field_passwordConfirm) {
          this.showError('Passwords do not match.');
          isValid = false;
        }

        // Validate password length (e.g., minimum 8 characters)
        if (!this.formData.field_password || this.formData.field_password.length < 8) {
          this.showError('Password must be at least 8 characters long.');
          isValid = false;
        }

        // Validate email format
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!this.formData.field_email || !emailPattern.test(this.formData.field_email)) {
          this.showError('Please enter a valid email address.');
          isValid = false;
        }

        // If valid, emit the formData
        if (isValid) {
            this.$emit('submit-step1', this.formData); // Emit form data to parent
        }
  
        return isValid;
      }
    }
  };
  </script>
  
  <style scoped>
  /* Fade transition for both fade-in and fade-out */
  .fade-enter-active, .fade-leave-active {
    transition: opacity 1s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  </style>
  