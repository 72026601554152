// Import the components
import ContractorRegistration from '../views/marketplace/contractor/Register.vue'
import ContractorForgotPassword  from '../views/marketplace/contractor/ForgotPassword.vue'

// Define the routes
const marketplaceRoutes = [
    // Contractor registration
    {
        path: '/marketplace/contractor/register',
        name: 'ContractorRegistration',
        component: ContractorRegistration,
        meta: {
          contractorHeader: true,
          breadcrumbs: false
        }
    },
    // Forgot password
    {
        path: '/marketplace/contractor/forgot-password',
        name: 'ContractorForgotPassword',
        component: ContractorForgotPassword,
        meta: {
          contractorHeader: true,
          breadcrumbs: false
        }
    },
]

export default marketplaceRoutes;