<template>
    <div class="space-y-4">
        <div v-if="this.error" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
            <span class="block sm:inline">{{ this.errorMessage }}</span>
        </div>

        <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full md:w-1/2 px-3">
                <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-availability">
                    Availability
                </label>

                <div class="relative">
                    <select @change="availabilityChange($event)" class="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight" id="grid-availability" v-model="this.formData.selectedAvailability">
                        <option v-for="availability in availabilityOptions" :value="availability.value">{{ availability.label }}</option>
                    </select>
                </div>

                <Popover v-if="calendarOpen" class="relative">
                    <PopoverPanel class="absolute z-10">
                        <Datepicker class="my-3" v-model="date" inline autoApply @update:modelValue="updateCalendarDate" hideOffsetDates :enableTimePicker="false" ref="datepicker"></Datepicker>
                    </PopoverPanel>
                </Popover>
            </div>
            <div class="w-full md:w-1/2 px-3">
                <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-rate">
                    Rate
                </label>
                <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-rate" type="text" v-model="this.formData.field_rate">
            </div>
        </div>

        <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full md:w-1/2 px-3">
                <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-contractStatus">
                    Contract Status
                </label>
                <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-contractStatus" type="text" v-model="this.formData.field_contractStatus">
            </div>
            <div class="w-full md:w-1/2 px-3">
                <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-workTravel">
                    Will travel for work
                </label>
                <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-workTravel" type="text" v-model="this.formData.field_workTravel">
            </div>
        </div>

        <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
                <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-roster">
                    Roster
                </label>
                <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-roster" type="text" v-model="this.formData.field_roster">
            </div>
        </div>

        <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
                <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-roleDescription">
                    Short description of preferred role/project
                </label>
                <textarea class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight" name="" id="grid-roleDescription" cols="30" rows="3" v-model="this.formData.field_roleDescription"></textarea>
            </div>
        </div>

        <!-- Industry Dropdown -->
        <div>
            <label for="industry" class="block text-sm font-medium text-gray-700">Select Industry</label>
            <select
            id="industry"
            v-model="selectedIndustry"
            @change="fetchRoles"
            class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
            
                <option disabled value="">-- Select Industry --</option>
                <option v-for="industry in industries" :key="industry.id" :value="industry.id">{{ industry.name }}</option>
            </select>
        </div>
  
        <!-- Roles Dropdown (shown when an industry is selected) -->
        <div v-if="roles.length > 0">
            <label for="role" class="block text-sm font-medium text-gray-700">Select Role</label>
            <select
            id="role"
            v-model="selectedRole"
            @change="fetchQuestions"
            class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
                <option disabled value="">-- Select Role --</option>
                <option v-for="role in roles" :key="role.id" :value="role.id">{{ role.name }}</option>
            </select>
        </div>

        <!-- Questions and Text Areas (shown when questions are fetched for the selected role) -->
        <div v-if="questions.length > 0">
            <div v-for="(question, index) in questions" :key="question.id" class="space-y-2">
                <!-- Display the question -->
                <label :for="'question-' + question.id" class="block text-sm font-medium text-gray-700">
                    {{ question.question }}
                </label>
          
                <!-- Textarea for the user's answer to the question -->
                <textarea
                :id="'question-' + question.id"
                v-model="answers[index]"
                rows="4"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder="Write your answer here..."
                ></textarea>
            </div>
        </div>
    </div>
</template>
  
<script>
import contractorService from '@/services/marketplace/contractor.service';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
    components: {
        Datepicker
    },
    props: ['formData'],
    data() {
        return {
            // formData: {
            //     selectedAvailability: 0,
            //     field_rate: "",
            //     field_contractStatus: "",
            //     field_workTravel: "",
            //     field_roster: "",
            //     field_roleDescription: "",
            // },
            date: new Date(),
            availability: 0,
            calendarOpen: false,
            availabilityOptions: [
                { "value": 0, "label": "Select availability" },
                { "value": 0.2, "label": "1 week" },
                { "value": 0.5, "label": "2 weeks" },
                { "value": 1, "label": "1 month" },
                { "value": 999, "label": "Custom date" }
            ],
            industries: [],         // List of industries fetched from API
            selectedIndustry: '',   // Selected industry ID
            roles: [],              // List of roles based on selected industry
            selectedRole: '',       // Selected role ID
            questions: [],          // List of questions based on selected role
            answers: [],             // Array to hold the answers for each question
            error: false,
            errorMessage: "",
            fadeTimeout: null, // To store the timeout for error message fade out
        }
    },
    methods: {
        // Fetch industries when the component is mounted
        async fetchIndustries() {
            try {
                const response = await contractorService.getIndustries();
                this.industries = response.data;
            } catch (error) {
                console.error('Error fetching industries:', error);
            }
        },

        // Fetch roles when an industry is selected
        async fetchRoles() {
            this.roles = [];
            this.selectedRole = '';
            this.questions = [];
            this.answers = [];

            if (this.selectedIndustry) {
                try {
                    const response = await contractorService.getRoles(this.selectedIndustry);
                    this.roles = response.data;
                } catch (error) {
                    console.error('Error fetching roles:', error);
                }
            }
        },

        // Fetch questions when a role is selected
        async fetchQuestions() {
            this.questions = [];
            this.answers = [];
  
            if (this.selectedRole) {
                try {
                    const response = await contractorService.getQuestions(this.selectedRole);
                    this.questions = response.data;

                    // Initialize answers array with empty strings, one for each question
                    this.answers = this.questions.map(() => '');
                } catch (error) {
                    console.error('Error fetching questions:', error);
                }
            }
        },
        availabilityChange(event) {
            if (event.target.value == 999) {
                this.calendarOpen = true
            } else {
                this.calendarOpen = false
            }
        },
        updateCalendarDate(newDate) {
            let newSelectedDate = (newDate.getTime() / 1000)
            this.calendarOpen = false

            // If we already have 5 dates, remove the latest one
            if (this.availabilityOptions.length >= 6) {
                this.availabilityOptions.pop();
            }

            // Add the new date to the dropdown
            this.availabilityOptions.push({
                "value": newSelectedDate,
                "label": "From " + newDate.toLocaleDateString()
            })

            this.selectedAvailability = newSelectedDate
        },
        showError(message) {
        this.error = true;
        this.errorMessage = message;
  
        // Clear previous timeout if it exists
        if (this.fadeTimeout) {
          clearTimeout(this.fadeTimeout);
        }
  
        // Hide the error after 5 seconds
        this.fadeTimeout = setTimeout(() => {
          this.error = false;
        }, 5000); // Error message stays visible for 5 seconds
      },
      validate() {
        // Reset error state
        this.error = false;
        this.errorMessage = '';
  
        let isValid = true;

        // Validation for dynamic answers array
        this.answers.forEach((answer, index) => {
            if (!answer || answer.trim() === '') {
            this.showError(`All questions are mandatory.`);
            isValid = false;
            }
        });
    
        if (!this.selectedRole) {
            this.showError('Please select a role from the list.');
            isValid = false;
        }

        if (!this.selectedIndustry) {
            this.showError('Please select an industry from the list.');
            isValid = false;
        }

        if (!this.formData.field_roleDescription) {
          this.showError('Please provide a short description of your preferred role.');
          isValid = false;
        }

        if (!this.formData.field_roster) {
          this.showError('The Roster field is mandatory.');
          isValid = false;
        }

        if (!this.formData.field_contractStatus) {
          this.showError('The Contract Status field is mandatory.');
          isValid = false;
        }

        if (!this.formData.field_rate) {
          this.showError('Select a valid rate (excl. super)');
          isValid = false;
        }

        if (!this.formData.selectedAvailability) {
          this.showError('Please select your availability.');
          isValid = false;
        }

        // If valid, emit the formData
        if (isValid) {
            this.$emit('submit-step3', this.formData); // Emit form data to parent
        }
  
        return isValid;
      }
    },
    mounted() {
        // Fetch industries when the component is displayed
        this.fetchIndustries();
    }
};
</script>