<!-- <<template class="h-screen bg-gray-100">
    <div class="pt-7 flex justify-center">
      <div class="bg-white rounded-xl p-5 flex flex-col">
        <h1 class="text-3xl text-olive font-bold">You are logged in as {{ this.profile.email }}</h1>
        <div class="text-xl">{{ this.profile }}</div>
      </div>
    </div>
  </template>> -->
<template>



  <div class="pt-7 flex justify-center">





    

    <div class="display-none bg-white shadow-md rounded-xl px-8 pt-6 pb-8 mb-4 flex flex-col w-3/4">



  










      
      <div class="mb-4">
          <h3 class="font-bold">Personal details</h3>
      </div>

      

      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full md:w-1/2 px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-fname">
            First Name
          </label>
          <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-fname" type="text" v-model="this.profile.first_name">
        </div>
        <div class="w-full md:w-1/2 px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-lname">
            Last Name
          </label>
          <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-lname" type="text" v-model="this.profile.last_name">
        </div>
      </div>
      
      
      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full md:w-1/2 px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-email">
            Email (contact only)
          </label>
          <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-email" type="text" v-model="this.profile.email">
        </div>
        <div class="w-full md:w-1/2 px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-phone">
            Phone
          </label>
          <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-phone" type="text" v-model="this.profile.phone">
        </div>
      </div>

      <div class="mb-4">
          <h3 class="font-bold">Next contract information</h3>
      </div>
      
      <div class="flex flex-wrap -mx-3 mb-2">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-visa">
            Visa status
          </label>
          <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-visa" type="text" v-model="this.profile.visa">
        </div>
      </div>

      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full md:w-1/2 px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-availability">
            Availability
          </label>
          <div class="relative">
            <select @change="availabilityChange($event)" class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-availability" v-model="this.selectedAvailability">
              <option v-for="availability in availabilityOptions" :value="availability.value">{{ availability.label }}</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
            </div>
          </div>

          <Popover v-if="calendarOpen" class="relative">
            <PopoverPanel class="absolute z-10">
              <Datepicker class="my-3" v-model="date" inline autoApply @update:modelValue="updateCalendarDate" hideOffsetDates :enableTimePicker="false" ref="datepicker"></Datepicker>
            </PopoverPanel>
          </Popover>
        </div>
        <div class="w-full md:w-1/2 px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-rate">
            Rate
          </label>
          <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-rate" type="text" v-model="this.profile.rate">
        </div>
      </div>
        
      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full md:w-1/2 px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-industry">
            Industry
          </label>
          <select class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  id="grid-industry" v-model="this.profile.industry">
            <option>Mining</option>
          </select>
        </div>
        <div class="w-full md:w-1/2 px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-role">
            Role
          </label>
          <select class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  id="grid-role" v-model="this.profile.role">
            <option>Field Assistant</option>
            <option>Geology</option>
          </select>
        </div>
      </div>

      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full md:w-1/2 px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-workstate">
            Will work outside home state
          </label>
          
          <select class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  id="grid-workstate" v-model="this.profile.work_state">
            <option value="Y">Yes</option>
            <option value="N">No</option>
          </select>
        </div>
        <div class="w-full md:w-1/2 px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-workoverseas">
            Will work overseas
          </label>
          <select class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  id="grid-workoverseas" v-model="this.profile.work_overseas">
            <option value="Y">Yes</option>
            <option value="N">No</option>
          </select>
        </div>
      </div>

      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full md:w-1/2 px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-contract_type">
            Contract Type
          </label>
          <select class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  id="grid-contract_type" v-model="this.profile.contract_type">
            <option>PAYG</option>
            <option>PTY LTD</option>
          </select>
        </div>
        <div class="w-full md:w-1/2 px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-roster">
            Roster
          </label>
          <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-roster" type="text" v-model="this.profile.roster">
        </div>
      </div>

      <div class="flex flex-wrap -mx-3 mb-2">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-ideal_role">
            Ideal role / opportunity
          </label>
          <textarea class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="" id="grid-ideal_role" cols="30" rows="3" v-model="this.profile.ideal_role"></textarea>
        </div>
      </div>

      <div class="mb-4">
          <h3 class="font-bold">Resume</h3>
      </div>

      <template v-if="!this.resumeData">
        <div v-if="isFileUploaded && !error" class="flex flex-col items-center justify-center w-full">
          <div>You are about to submit the following resume file for processing:</div>
          <div class="flex">
            <div class="text-olive">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-9 w-9" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
              </svg>
            </div>
            
            <div class="text-xl">
              {{ this.uploadedFile.name }}
            </div>
          </div>
        </div>

        <div v-if="fileError" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded my-3" role="alert">
          <strong class="font-bold">Error! </strong>
          <span class="block sm:inline">{{ this.fileErrorMessage }}</span>
          <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
            <svg class="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
          </span>
        </div>

        <div v-if="!isFileUploaded" class="flex items-center justify-center w-full">
          <label class="flex flex-col w-full h-32 border-4 border-olive border-dashed hover:bg-gray-100 hover:border-gray-300">
            <div class="flex flex-col items-center justify-center pt-7">
              <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-gray-400 group-hover:text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
              </svg>
              <p class="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                Click to select a resume file
              </p>
            </div>
            <input type="file" class="opacity-0" @change="fileUpload">
          </label>
        </div>
        
        <div class="flex justify-evenly p-4">
          <button  v-if="isFileUploaded" class="w-40 px-4 py-2 text-white bg-red-600 rounded shadow-xl" @click="cancelUpload">Cancel</button>
          <button  v-if="isFileUploaded" class="w-40 px-4 py-2 text-white bg-sky-500 rounded shadow-xl" @click="processResumeUpload">Upload</button>
        </div>
      </template>

      <template v-else>
        <div v-if="this.resumeData.status == 1" class="flex items-center p-6 border-2 border-olive rounded-lg bg-gray-50 shadow-lg">
          <!-- Icon Section -->
          <div class="w-12 h-12 bg-white rounded-full flex items-center justify-center mb-4 md:mb-0 md:mr-4">
            <svg class="w-6 h-6 text-olive" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 9h4M10 13h4M10 17h4M3 3h18v18H3V3z"></path>
            </svg>
          </div>

          <!-- Content Section -->
          <div class="flex-1 mb-4 md:mb-0">
            <p class="text-olive font-semibold mb-2">You currently have a resume uploaded ({{ this.resumeData.file_name }})</p>
            <p class="text-olive">The resume file {{ filename }} was last updated on {{ this.resumeData.upload_date }}.</p>
          </div>

          <!-- Action Section -->
          <button @click="replaceResume" class="bg-sky-500 text-white px-4 py-2 rounded-lg hover:text-gray-300 w-full md:w-auto">
            Replace Resume
          </button>
        </div>

        <div class="w-full p-4 bg-gray-100 border border-gray-300 rounded-lg shadow-md flex flex-col md:flex-row items-center">
  <!-- Icon Section -->
  <div class="w-12 h-12 bg-white rounded-full flex items-center justify-center mb-4 md:mb-0 md:mr-4">
    <svg class="w-6 h-6 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 11a3 3 0 100-6 3 3 0 000 6zm0 6a6 6 0 00-6 6h12a6 6 0 00-6-6z"></path>
    </svg>
  </div>

  <!-- Content Section -->
  <div class="flex-1 mb-4 md:mb-0">
    <p class="text-gray-800 font-semibold mb-2">Upload a Profile Picture</p>
    <p class="text-gray-700">You can upload your profile picture here. The image will be used to represent you on your profile.</p>
  </div>

  <!-- Upload Section -->
  <label for="file-upload" class="bg-blue-500 text-white px-4 py-2 rounded-lg cursor-pointer hover:bg-blue-600 flex items-center">
    <svg class="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6M9 16h6M10 8h4M6 20h12M4 6h16v12H4V6z"></path>
    </svg>
    Select Profile Picture
    <input id="file-upload" type="file" @change="handleFileUpload" class="sr-only">
  </label>
</div>


<div class="w-full p-4 bg-gray-100 border border-gray-300 rounded-lg shadow-md flex flex-col md:flex-row items-center mb-4">
  <!-- Icon Section -->
  <div class="w-24 h-24 bg-white rounded-full flex items-center justify-center mb-4 md:mb-0 md:mr-4">
    <img :src="profilePictureUrl" alt="Profile Picture" class="w-full h-full object-cover rounded-full" />
  </div>

  <!-- Content Section -->
  <div class="flex-1 mb-4 md:mb-0">
    <p class="text-gray-800 font-semibold mb-2">Profile Picture Uploaded</p>
    <p class="text-gray-700">Your current profile picture is displayed above. If you wish to upload a new picture, use the button below.</p>
  </div>

  <!-- Action Section -->
  <label for="file-upload" class="bg-blue-500 text-white px-4 py-2 rounded-lg cursor-pointer hover:bg-blue-600 flex items-center">
    <svg class="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6M9 16h6M10 8h4M6 20h12M4 6h16v12H4V6z"></path>
    </svg>
    Replace Picture
    <input id="file-upload" type="file" @change="handleFileUpload" class="sr-only">
  </label>
</div>




        <div v-if="this.resumeData.status == 0" class="flex items-center p-6 border-2 border-olive rounded-lg bg-gray-50 shadow-lg">
          <div class="flex-shrink-0 mr-4">
            <!-- Clock Icon -->
             <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-olive" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
          <div>
            <h2 class="text-lg font-semibold text-gray-800 mb-2">Your resume is being reviewed</h2>
            <p class="text-gray-600 mb-4">Please wait a few moments while we process your information. This may take a few minutes.</p>
            
            <div class="flex items-center">
              <!-- File Icon -->
               <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M7 2a1 1 0 00-1 1v18a1 1 0 001 1h10a1 1 0 001-1V7.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 1H7z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M13 3v4a1 1 0 001 1h4" />
              </svg>
              <span class="text-gray-700 font-medium">{{ this.resumeData.file_name }}</span>
            </div>
          </div>
        </div>



        <!-- <div v-if="this.resumeData.status == 0" class="bg-gray-200 p-3 rounded-xl">
          
          The resume file you have uploaded (<b>{{ this.resumeData.file_name }}</b>) uploaded on <b>{{ this.resumeData.upload_date }}</b> is currently being processed. Please check back later to view your resume information or replace your resume file once the processing of the current resume file is complete.
        </div> -->

        
      </template>

      <div class="pt-5 sm:flex sm:flex-row-reverse">
          <button class="bg-sky-500 hover:bg-sky-400 text-white py-2 px-4 rounded" type="button" @click="submitUpdate">
            Save
          </button>
        </div>
    </div>
  </div>
</template>

<script>

let apiEndpoint = process.env.VUE_APP_API_BASEURL
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import BigCardContractor from '../../../components/marketplace/contractor/BigCardContractor.vue';
  
export default {
  name: 'MarketplaceContractorProfileEdit',
  components: {
    Datepicker,
    BigCardContractor
  },
  data() {
    return {
      date: new Date(),
      profile: [],
      availability: 0,
      calendarOpen: false,
      availabilityOptions: [
        { "value": 0, "label": "Select availability" },
        { "value": 0.2, "label": "1 week" },
        { "value": 0.5, "label": "2 weeks" },
        { "value": 1, "label": "1 month" },
        { "value": 999, "label": "Custom date" }
      ],
      selectedAvailability: 0,
      resumeData: false,
      editResumeData: false,
      isFileUploaded: false,
      uploadedFile: null,
      fileSuccess: false,
      fileError: false,
      fileErrorMessage: '',
    };
  },
  methods: {
    async getResume() {
      let response = await fetch(apiEndpoint + "/marketplace.php?getResume", {
        headers: {
          'Authorization': 'Bearer ' +  localStorage.getItem('contractorToken'),
        },
      })

      let resp = await response.json();

      if (resp.success !== false) {
        this.resumeData = resp.data
      }
    },
    fileUpload(event) {
      const allowedTypes = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
      this.fileSuccess = false
      this.fileError = false
      this.fileErrorMessage = ''
      
      
      let currentFile = event.target.files[0]
      if (allowedTypes.includes(currentFile.type)) {
        this.uploadedFile = event.target.files[0]
        this.isFileUploaded = true
      } else {
        this.fileError = true
        this.fileErrorMessage = 'Invalid file selected. Only PDF, DOC and DOCX files are allowed.'
      }
    },
    cancelUpload() {
      this.uploadedFile = null
      this.isFileUploaded = false
      this.fileError = false
      this.fileErrorMessage = ''
    },
    async processResumeUpload() {
      if (this.isFileUploaded) {
        var data = new FormData()
        data.append('file', this.uploadedFile)

        fetch(apiEndpoint + "/marketplace.php?uploadResume", {
          method: 'POST',
          body: data,
          headers: {
            'Authorization': 'Bearer ' +  localStorage.getItem('contractorToken'),
          },
        })
        .then(response => response.json())
        .then(data => {
          if (data.error) {
            this.uploadedFile = null
            this.isFileUploaded = false
            this.fileError = true
            this.fileErrorMessage = data.errorMessage
          } else if (data.success) {
            this.uploadedFile = null
            this.isFileUploaded = false
            this.fileError = false
            this.fileErrorMessage = ''
            this.fileSuccess = true

            this.getResume()
          }
        })
      }
    },
    async getContractorProfile() {
      try {
        let response = await fetch(apiEndpoint + "/marketplace.php?getContractorAccount", {
          headers: {
            'Authorization': 'Bearer ' +  localStorage.getItem('contractorToken'),
          },
        })
        this.profile = await response.json();
        this.setAvailability(this.profile.availability)
        // If the availability is higher than 1 we need to add the custom date to the array
        if (this.availabilityOptions.length >= 6) {
          this.availabilityOptions.pop();
        }
        
        
        if (this.selectedAvailability > 1) {
          let customDate = new Date(this.profile.availability * 1000)
          this.availabilityOptions.push({
            "value": this.selectedAvailability,
            "label": 'From: ' + customDate.toLocaleDateString()
          })
        }
      } catch (error) {
        console.log(error);
      }
    },
    setAvailability(v) {
      if (v == null) {
        this.selectedAvailability = 0
      } else {
        this.selectedAvailability = v
      }
    },
    async submitUpdate() {
      let postObject = {
          first_name: this.profile.first_name,
          last_name: this.profile.last_name,
          email: this.profile.email,
          phone: this.profile.phone,
          visa: this.profile.visa,
          availability: this.selectedAvailability,
          rate: this.profile.rate,
          industry: this.profile.industry,
          role: this.profile.role,
          work_state: this.profile.work_state,
          work_overseas: this.profile.work_overseas,
          contract_type: this.profile.contract_type,
          roster: this.profile.roster,
          ideal_role: this.profile.ideal_role
        }
        
        try {
          await fetch(apiEndpoint + "/marketplace.php?updateContractorAccount", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' +  localStorage.getItem('contractorToken')
            },
            body: JSON.stringify(postObject),
          })
          .then(response => response.json())
          .then(data => {
            if (data.success === false) {
              this.error = true
              this.errorMessage = data.message
            } else {
              // Redirect to profiles
              this.$router.push('/marketplace/contractor/profile');
            }
          })
        } catch (error) {
          console.log('something went realllly worng')
      //     // this.saveButtonText = 'Save changes'
        }
      // }
    },
    availabilityChange(event) {
      if (event.target.value == 999) {
        this.calendarOpen = true
      } else {
        this.calendarOpen = false
      }
    },
    updateCalendarDate(newDate) {
      let newSelectedDate = (newDate.getTime() / 1000)
      this.calendarOpen = false

      // If we already have 5 dates, remove the latest one
      if (this.availabilityOptions.length >= 6) {
        this.availabilityOptions.pop();
      }

      // Add the new date to the dropdown
      this.availabilityOptions.push({
        "value": newSelectedDate,
        "label": "From " + newDate.toLocaleDateString()
      })

      this.selectedAvailability = newSelectedDate
    },
  },
  created() {
    this.getContractorProfile();
    this.getResume();
  }
}
</script>
  