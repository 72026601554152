<template>
    <div v-if="this.error" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
        <span class="block sm:inline">{{ this.errorMessage }}</span>
    </div>

    <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full md:w-1/2 px-3">
            <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-firstName">
                First name
            </label>
            <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-firstName" type="text" v-model="this.formData.field_firstName">
        </div>
        <div class="w-full md:w-1/2 px-3">
            <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-lastName">
                Last name
            </label>
            <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-lastName" type="text" v-model="this.formData.field_lastName">
        </div>
    </div>

    <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full px-3">
            <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-phone">
                Phone
            </label>
            <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-phone" type="tel" v-model="this.formData.field_phone">
        </div>
    </div>
    
    <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full px-3">
            <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-address">
                Address
            </label>
            <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-address" type="text" v-model="this.formData.field_address">
        </div>
    </div>

    <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full px-3">
            <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-visaStatus">
                Visa status
            </label>
            <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-visaStatus" type="text" v-model="this.formData.field_visaStatus">
        </div>
    </div>
</template>

<script>
let apiEndpoint = process.env.VUE_APP_API_BASEURL

export default {
    props: ['formData'],
    data() {
        return {
            // formData: {
            //     field_firstName: "",
            //     field_lastName: "",
            //     field_phone: "",
            //     field_address: "",
            //     field_visaStatus: "",
            // },
            error: false,
            errorMessage: ""
        }
    },
    methods: {
      showError(message) {
        this.error = true;
        this.errorMessage = message;
  
        // Clear previous timeout if it exists
        if (this.fadeTimeout) {
          clearTimeout(this.fadeTimeout);
        }
  
        // Hide the error after 5 seconds
        this.fadeTimeout = setTimeout(() => {
          this.error = false;
        }, 5000); // Error message stays visible for 5 seconds
      },
      validate() {
        // Reset error state
        this.error = false;
        this.errorMessage = '';
  
        let isValid = true;
  
        // Validate phone number
        if (!this.formData.field_visaStatus) {
          this.showError('A valid visa status is required.');
          isValid = false;
        }

        // Validate password length (e.g., minimum 8 characters)
        if (!this.formData.field_address || this.formData.field_address.length < 5) {
          this.showError('Please enter a valid address.');
          isValid = false;
        }

        // Validate phone number
        if (!this.formData.field_phone) {
          this.showError('A valid phone number is required.');
          isValid = false;
        }

        // Validate first and last name
        if (!this.formData.field_firstName || !this.formData.field_lastName) {
          this.showError('Please provide both your first and last name.');
          isValid = false;
        }

        // If valid, emit the formData
        if (isValid) {
            this.$emit('submit-step2', this.formData); // Emit form data to parent
        }
  
        return isValid;
      }
    }
};
</script>

<style scoped>
/* Fade transition for both fade-in and fade-out */
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
